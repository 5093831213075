import { navigate } from 'gatsby';
import React, { useEffect } from 'react';

// sendGrid.setApiKey(process.env.SEND_GRID_API_KEY);

const API_KEY = process.env.GATSBY_SENDGRID_API_KEY;

function addNewsletter() {
    const contactListId = '152f5d67-beee-4385-99ce-0533c6ada793'; //newletter
    const link = `https://api.sendgrid.com/v3/marketing/contacts`;
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let referrer = params.get('refer');
    let email = params.get('email');
    if (referrer === null) {
        referrer = "nobody";
    }
    console.log(email);

    return fetch(link, {
        headers: {
            Authorization: `Bearer ${API_KEY}`,
            'Content-Type': 'application/json'
        },
        method: 'PUT',
        body: JSON.stringify({
            list_ids: [contactListId],
            contacts: [
                {
                    email,
                    custom_fields: {
                        // e1_T is the referred_by id
                        e1_T: referrer,
                        e3_T: "true"
                    }
                }
            ]
        })
    });
}


function updateReferrals() {
    const link = `https://api.sendgrid.com/v3/marketing/contacts`;
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let referrer = params.get('refer');
    let count = params.get('count');
    
    // if count not included in link exit because don't want to overwrite referrals
    if (count === null) {
        return (
            console.log("No count included in link")
        );
    }

    if (referrer === null) {
        return (
            console.log("No referrer included in link")
        );
    }

    // convert string to number (Typescript trick)
    let newcount = +count;
    
    // increment count by 1 for new referral
    ++newcount;
   
   
    return fetch(link, {
        headers: {
            Authorization: `Bearer ${API_KEY}`,
            'Content-Type': 'application/json'
        },
        method: 'PUT',
        body: JSON.stringify({
            contacts: [
                {
                    "email": referrer,
                    custom_fields: {
                        // e4_N is the referrals id
                        e4_N: newcount,
                    }
                }
            ]
        })
    });
}

const Validation: React.FunctionComponent<{}> = () => {
    
   
    function handleValidation() {
        addNewsletter()
            .then(data => {
                console.log({
                    data
                });
                if (data.result === 'error') {
                    throw data;
                }

                // redirect to new page after submission
                navigate('/welcome/');
                
                // update referrals number for referrer
                updateReferrals();


            })
            .catch(error => {
                //setError(error.msg);
                console.log(error);
                navigate('/404/');
            });
    }

    useEffect(()=> {
        handleValidation();
    },[]);

    return (
        null
    );
};

export default Validation;
